import React from "react";
import Typography from "@mui/material/Typography";

const NotaDetailSubTotal = ({ k, strSubTotal, item_unit, item_subtotal }) => {
  return (
    <>
      <tr key={k + 2656}>
        <td align="left">
          <Typography
            sx={{ paddingX: 1, fontWeight: "bold", fontSize: "1.1rem" }}
            variant="body2"
            component="p"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{strSubTotal} x {item_unit}{" "}
          </Typography>
        </td>
        <td align="right">
          <Typography
            sx={{ paddingX: 1, fontWeight: "bold", fontSize: "1.1rem" }}
            variant="body2"
            component="p"
          >
            {item_subtotal}
          </Typography>
        </td>
      </tr>
    </>
  );
};

export default NotaDetailSubTotal;
