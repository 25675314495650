import React from "react";
import Typography from "@mui/material/Typography";
const NotaDetailPotongan = ({
  k,
  strpotongan,
  item_potongan,
  item_unit,
  item_totpot,
}) => {
  if (strpotongan.length > 0) {
    // tampilkan potongan jika ada
    return (
      <>
        <tr key={k + 3856}>
          <td align="left">
            <Typography sx={{ paddingX: 1 }} variant="body2" component="p">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ {strpotongan} ] x{" "}
              {item_unit}{" "}
            </Typography>
          </td>
          <td align="right" className="potongan">
            <Typography sx={{ paddingX: 1 }} variant="body2" component="p">
              ({item_totpot})
            </Typography>
          </td>
        </tr>
      </>
    );
  } else {
    return <></>;
  }
};

export default NotaDetailPotongan;
